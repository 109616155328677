import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, EMPTY, map, switchMap, tap } from 'rxjs';
import { PlatformEnvironmentToken } from '@cca-environment';
import { HeaderData } from './page-title-data';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private readonly baseTitle = inject(PlatformEnvironmentToken).baseTitle;
  private readonly headerService = inject(HeaderService);
  private readonly title = inject(Title);
  private readonly subject = new BehaviorSubject<HeaderData[]>([]);

  constructor() {
    const transloco = inject(TranslocoService);
    this.subject
      .pipe(
        map((stack) => stack.at(-1) ?? null),
        switchMap((pageTitle) => {
          if (!pageTitle) {
            this.clearTitleAndHeader();
            return EMPTY;
          }

          // set page title directly
          this.headerService.set(pageTitle);

          // resolve for title
          if (pageTitle?.translationKey) {
            return transloco.langChanges$.pipe(
              switchMap((lang) =>
                transloco.load(`${pageTitle.translocoScope}/${lang}`).pipe(
                  tap(() => {
                    const translatedTitle = transloco.translate(
                      pageTitle.translationKey,
                      pageTitle.translocoParams,
                    );
                    this.setTitle(translatedTitle);
                  }),
                ),
              ),
            );
          }

          this.setTitle(pageTitle?.title);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private clearTitleAndHeader() {
    this.setTitle(null);
    this.headerService.set(null);
  }

  private setTitle(title: string | null | undefined) {
    // no resolving needed its a raw value
    if (title) {
      this.title.setTitle(`${this.baseTitle} - ${title}`);
    } else {
      this.title.setTitle(`${this.baseTitle}`);
    }
  }

  public pop() {
    const stack = this.subject.value;
    stack.pop();

    this.subject.next([...stack]);
  }

  public push(value: HeaderData): number {
    const stack = this.subject.value;
    const index = stack.push(value) - 1;

    this.subject.next([...stack]);

    return index;
  }

  public replace(index: number, value: HeaderData) {
    const stack = this.subject.value;
    this.subject.next(stack.with(index, value));
  }
}
